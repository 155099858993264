import React from "react";
import { useEffect, useState } from 'react';
import Collapsible from "./Collapsible";

export default function FAQ({ }) {
	return (
		<>
			<section id="faq">
				<div className="row">
					<div className="col-12 p-5">
						<h2 className="green text-center" style={{ fontWeight: "bold" }}>MASZ PYTANIA?</h2>
						<div className="text-center">Poniżej odpowiadamy na najczęstsze z nich.</div>
					</div>
					<div className="col-12 px-5 col-md-8 offset-md-2">
						<div className="line"></div>

						<Collapsible label="Czy wszystkie konta biorą udział w loterii?">
							Nie, udział w loterii bierze otwarte w aplikacji GOmobile konto o nazwie: Konto Otwarte na Ciebie, z Kartą Otwartą na Dzisiaj w wersji mobilnej, w promocji Jesteś Wygrywem.
						</Collapsible>

						<div className="line"></div>

						<Collapsible label="Jak właściwie otworzyć konto w promocji?">
							Otwórz konto online: zeskanuj QR kod i  pobierz aplikację GOmobile Banku BNP Paribas – kod umieszczony na stronie przeniesie Cię bezpośrednio do sklepu z aplikacjami, a po otwarciu aplikacji trafisz na ścieżkę otwarcia odpowiedniego konta. Otwórz konto osobiste w promocji Jesteś wygrywem – zapoznaj się z warunkami i zaakceptuj je.
						</Collapsible>

						<div className="line"></div>

						<Collapsible label="Czy żeby wziąć udział w loterii, muszę spełnić też wszystkie warunki promocji?">
							Nie, aby wziąć udział w loterii wystarczy otworzyć Konto Otwarte na Ciebie z Kartą Otwartą na Dzisiaj w wersji mobilnej w promocji Jesteś wygrywem oraz przystąpić do loterii. Jeżeli chcesz zdobyć dodatkowe 50 zł, wtedy musisz spełnić pozostałe warunki np. zapewnić wpływy 1000 zł miesięcznie na swoim koncie.
						</Collapsible>

						<div className="line"></div>

						<Collapsible label="Jak otrzymam powiadomienie o wygranej?">
							Organizator wysyła powiadomienie o wygranej do 3 dni roboczych od momentu losowania (daty losowań znajdują się w regulaminie). Organizator wysyła powiadomienie w wiadomości e-mail oraz SMS. Laureaci nagród I, II oraz III stopnia zobowiązani są do uzupełnienia formularza laureata w ciągu 72h. Po tym czasie nagroda przepadnie. Dane laureatów, który uzupełnili formularz laureata niezwłocznie publikowane są na stronie www loterii.
						</Collapsible>

						<div className="line"></div>

						<Collapsible label="Co zrobić żeby odebrać nagrodę?">
							Aby odebrać nagrodę należy uzupełnić formularz laureata w ciągu 72h od otrzymania powiadomienia o wygranej (w przypadku nagród I, II oraz III stopnia). Organizator w odpowiedzi przekaże nagrodę przelewem do 21 dni od momentu uzupełnienia formularza laureata. Nagrody IV stopnia przekazywane są automatycznie w wiadomości na wskazany w formularzu zgłoszeniowym adres e-mail.
						</Collapsible>

						<div className="line"></div>
					</div>
				</div>
				<div className="row text-center mt-5 mb-3">
					<div className="col-12">
						Masz więcej pytań?
					</div>
				</div>
				<div className="row text-center pb-5">
					<div className="col-12">
						<a href="mailto:kontakt@jesteswygrywem.pl" className="button green">Napisz do nas <i className="arrow right"></i></a>
					</div>
				</div>
			</section>
		</>
	);
}
