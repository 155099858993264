import React from "react";
import { useEffect, useState } from 'react';

export default function CookiesMessage({ isCookieConsentGiven }) {
	const [cookiesAccepted, setCookiesAccepted] = useState(false);

	const onCookieAccept = () => {
		localStorage.setItem("cookies_accepted", "1")
		setCookiesAccepted(true);
		isCookieConsentGiven(true);

		//let scripts = document.querySelectorAll(".cookies_cat_2, .cookies_cat_4");
		//console.log(scripts);

		//for (let i = 0; i < scripts.length; i++) {
		//	scripts[i].setAttribute("type", "text/javascript");
		//}
	}

	useEffect(() => {
		if (localStorage.getItem("cookies_accepted") == "1") {
			setCookiesAccepted(true);
			isCookieConsentGiven(true);
		}
	}, []);

	return (
		<>
			{!cookiesAccepted &&
				<div className="cookies_banner">
					<div className="cookies_alert">
						<p>Drogi Użytkowniku, na niniejszej witrynie wykorzystywane są pliki cookies i&nbsp;podobne technologie. Korzystanie z witryny bez zmiany ustawień dotyczących cookies oznacza, że&nbsp;będą one umieszczone w&nbsp;Twoim urządzeniu.</p>
						<p>Administratorem danych osobowych zbieranych w&nbsp;ramach korzystania z&nbsp;niniejszej witryny jest IQ Marketing (Poland) sp. z&nbsp;o.o. z&nbsp;siedzibą w&nbsp;Warszawie. Zbierane dane to m.&nbsp;in.: liczba odwiedzin, kraj, lokalizacja, przeglądarka, czas wizyty. Dane te zbierane są w&nbsp;celu zapewnienia prawidłowego funkcjonowania strony, w&nbsp;celu utrzymania sesji na stronie oraz zapewnienia bezpieczeństwa, a&nbsp;także w&nbsp;celach analitycznych i&nbsp;statystycznych. Odbiorcami danych są także partnerzy administratora świadczący usługi związane z&nbsp;funkcjonowaniem portalu.</p>
						<p>Przysługuje Ci prawo do żądania od administratora dostępu, sprostowania, usunięcia lub ograniczenia przetwarzania danych, wniesienia sprzeciwu, a&nbsp;także prawo skorzystania z&nbsp;innych praw wymienionych szczegółowo w&nbsp;<a href="/jesteswygrywem/polityka-prywatnosci" target="_blank">Polityce Prywatności</a>. Tam też znajdują się informacje jak zakomunikować nam wolę skorzystania z tych praw.</p>
						<p>Oświadczam, iż zapoznałem/am się z&nbsp;obowiązkiem informacyjnym administratora danych osobowych zawartym w&nbsp;<a href="/jesteswygrywem/polityka-prywatnosci" target="_blank">polityce prywatności</a>.</p>
						<form action="accept_cookies_banner" method="POST" className="form">
							<button type="button" onClick={onCookieAccept}>Akceptuję</button>
						</form>
					</div>
				</div>
			}
		</>
	);
}
