import React, { useEffect, useState } from 'react';
import moment from "moment";
import 'moment/locale/pl'
import axios from 'axios';

export default class NewCalendar extends React.Component {

	constructor(props) { //w komponentach klasowych nie można użyć useEffect, więc używamy konstruktora
		super(props)
		this.getData2(this.state.jsDateObject.getMonth() + 1, this.state.jsDateObject.getFullYear());
	}

	minDate = new Date("2023-02-01");
	maxDate = new Date("2024-04-01");

	state = {
		jsDateObject: new Date(),
		dateObject: moment(),
		allmonths: moment.months(),
		showPrev: true,
		showNext: true
	};

	daysInMonth = () => {
		return new Date(this.state.jsDateObject.getFullYear(), (this.state.jsDateObject.getMonth() + 1), 0).getDate();
	};

	firstDayOfMonth = () => {
		// akrobacje żeby wyciągnąć numer pierwszego dnia miesiąca
		let firstDay = new Date(this.state.jsDateObject.getFullYear() + "-" + (this.state.jsDateObject.getMonth() + 1)).getDay();

		firstDay = parseInt(firstDay) - 1;

		if (firstDay === -1)
			firstDay = 6;

		return firstDay;
	};

	month = () => {
		return this.state.allmonths[this.state.jsDateObject.getMonth()];
	};

	year = () => {
		return this.state.jsDateObject.getFullYear();
	};

	onPrev = () => {
		if (new Date(this.state.jsDateObject.getFullYear() + "-" + ((this.state.jsDateObject.getMonth() + 1).toString().padStart(2, '0')) + "-" + "01") > this.minDate) {
			var curr = this.state.jsDateObject;
			curr.setMonth(curr.getMonth() - 1, 1);

			this.setState({
				jsDateObject: curr,
				showNext: true
			});

			this.getData2(curr.getMonth() + 1, curr.getFullYear());

			if (curr.getMonth() + 1 == 2 && curr.getFullYear() == 2023) {
				this.setState({
					showPrev: false
				});
			}
		}
		else {
			this.setState({
				showPrev: false
			});
		}
	};

	onNext = () => {
		if (this.state.jsDateObject < this.maxDate) {
			var curr = this.state.jsDateObject;
			curr.setMonth(curr.getMonth() + 1, 1);

			this.setState({
				jsDateObject: curr,
				showPrev: true
			});

			this.getData2(curr.getMonth() + 1, curr.getFullYear());

			if (curr.getMonth() + 1 == 4 && curr.getFullYear() == 2024) {
				this.setState({
					showNext: false
				});
			}
		}
		else {
			this.setState({
				showNext: false
			});
		}
	};

	getData2 = async (month, year) => {
		const data = {
			month: month,
			year: year
		};

		try {
			//const response = await axios.post("https://lp.bnpparibas.pl/jesteswygrywem/ajax/get_winner_list", data);
			//const response = await axios.post("https://lp.bnpparibas.pl/jesteswygrywem/ajax/get_new_winner_list/" + year + "-" + String(month).padStart(2, '0'));
			//const response = await axios.post("https://api-301bnp-zabka.iq-digital.pl/get_winner_list2.php", data);
			const response = await axios.post("https://api-301bnp-zabka.iq-digital.pl/get_new_winner_list.php", data);
			this.props.onResult(response.data);
		} catch (error) {
			console.log(error);
		}
	}

	render() {
		return (
			<>
				<div id="month_switcher">
					<div className="col1" onClick={e => {
						this.onPrev();
					}}><i className="arrow left" style={this.state.showPrev ? {} : { visibility: "hidden" }}></i>
					</div>
					<div className="col2 nm">{this.month()} {this.year()}</div>
					{this.state.showNext &&
						<div className="col3" onClick={e => {
							this.onNext();
						}}><i className="arrow right"></i></div>
					}
				</div>
			</>
		);
	}
}
