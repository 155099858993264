import React from "react";
import { useEffect, useState } from 'react';

export default function BezcenneChwile({ onShowDownloadBanner }) {
	return (
		<>
			<section id="bezcenne_chwile">
				<div className="row">
					<div className="col-12 text-center">
						<h3 className="nobold mb-3">PROGRAM MASTERCARD® <b>BEZCENNE® CHWILE</b></h3>
					</div>
				</div>
				<div className="row text-center my-2 mb-5">
					<div className="col">
						<div className="line"></div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-6">
						<div className="gray_bg p-5 py-4" style={{ height: "100%" }}>
							<div className="mb-3"><b>Zarejestruj kartę</b> naszego banku w programie Mastercard® <b>Bezcenne® Chwile</b> i zgarnij e-vouchery o łącznej wartości <b>200 zł</b> na Twoje Pasje!</div>
							<div className="txt3 mb-4">Promocja trwa do 31.12.2023 r. lub do wyczerpania puli nagród.</div>
							<div>
								<a href="https://www.bnpparibas.pl/bezcenne-chwile" className="button green" target="_blank">Promocja zakończona <i className="arrow right"></i></a>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6 big_img">
						<img src={require("../img/new/bezcenne_chwile.jpg")} alt="" className="img-fluid" />
					</div>
				</div>

				<div className="row text-left remarks mb-2 mt-4">
					<div className="col-12">
						Nagrodą w promocji „Mobilni zyskują z Bankiem BNP Paribas” (Promocja) jest 16 000 punktów w Programie Mastercard Bezcenne Chwile (Program). Zarejestruj kartę w Programie, dodaj ją do elektronicznego portfela, w ciągu 30 dni 5x zapłać telefonem lub zegarkiem na min. 500 zł, odbierz punkty i wymień je np. na e-vouchery o wartości 200 zł. Promocja trwa do 31.12.23, ale nie dłużej niż do wyczerpania puli nagród. Organizatorem Programu oraz Promocji jest Mastercard Europe SA, działająca w Polsce poprzez Mastercard Europe SA Oddział w Polsce. Sprawdź szczegóły dostępne na stronie organizatora Programu – bezcennechwile.mastercard.pl.
					</div>
				</div>

				<div className="row text-center my-4 mb-4">
					<div className="col">
						<div className="line"></div>
					</div>
				</div>

				<div className="row text-center mb-3">
					<div className="col-12">
						Masz pytania?
					</div>
				</div>
				<div className="row text-center pb-5">
					<div className="col-12">
						<a href="mailto:kontakt@jesteswygrywem.pl" className="button green">Napisz do nas <i className="arrow right"></i></a>
					</div>
				</div>
			</section>
		</>
	);
}
