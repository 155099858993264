// import axios from 'axios';
import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate, redirect, useNavigate } from 'react-router-dom';

//import 'bootstrap/dist/css/bootstrap.min.css';
//import './styles/App.scss';
import './styles/NewApp.scss';

import NewMain from "./components/modules/NewMain";

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/jesteswygrywem/" exact element={<NewMain />} />
        </Routes>
      </BrowserRouter >

    </>
  )
}

export default App;
