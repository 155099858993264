import React from 'react';
import { useState, useEffect } from "react";
import Calendar from "../Calendar";
import CookiesMessage from "../CookiesMessage";
import { Link } from 'react-scroll'
// import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';
import Loteria from "../Loteria";
import Evouchery from "../Evouchery";
import BezcenneChwile from "../BezcenneChwile";

export default function NewMain() {
  const [isMobile, setIsMobile] = useState(false);
  const [isCookieConsentGivenC, setIsCookieConsentGivenC] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  //const [dailyPrize, setDailyPrize] = useState('<p className="prize_info">Laureat zostanie opublikowany po weryfikacji.</p>');
  //const [weeklyPrize, setWeeklyPrize] = useState('<p className="prize_info">Laureat zostanie opublikowany po weryfikacji.</p>');
  //const [monthlyPrize, setMonthlyPrize] = useState('<p className="prize_info">Laureat zostanie opublikowany po weryfikacji.</p>');

  const [showScroll, setShowScroll] = useState(false)
  const [viewPage, setViewPage] = useState("");
  const [showDownloadBanner, setShowDownloadBanner] = useState(false);

  React.useEffect(() => {
    if (window.innerWidth < 960)
      setIsMobile(true);
    else
      setIsMobile(false);

    window.addEventListener("resize", () => {
      if (window.innerWidth < 960)
        setIsMobile(true);
      else
        setIsMobile(false);
    })
  }, []);

  React.useEffect(() => {
    if (viewPage != "")
      window.scrollTo({ left: 0, top: document.getElementById("scroll_pos").offsetTop - 30, behavior: "smooth" });
  }, [viewPage]);

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 400) {
      setShowScroll(true)
    } else if (showScroll && window.scrollY <= 400) {
      setShowScroll(false)
    }
  };

  const onGoToTop = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  }

  window.addEventListener('scroll', checkScrollTop)

  //const onCalendarClick = (data) => {
  //  setDailyPrize(data.daily);
  //  setWeeklyPrize(data.weekly);
  //  setMonthlyPrize(data.monthly);
  //}

  const onToggleMenu = () => {
    if (isMenuOpen)
      setIsMenuOpen(false);
    else
      setIsMenuOpen(true);
  }

  const isCookieConsentGiven = (isCookieConsentGiven) => {
    setIsCookieConsentGivenC(isCookieConsentGiven);
  }

  return (
    <>
      <div id="cookies_script_box"></div>

      <CookiesMessage isCookieConsentGiven={isCookieConsentGiven} />

      {(isCookieConsentGivenC || localStorage.getItem("cookies_accepted") == "1") &&
        <>
          <Helmet>
            <script type="text/javascript" class="cookies_cat_2" nonce="8BA2D7" async
              src="https://www.googletagmanager.com/gtag/js?id=G-NE6QCSGW4R"></script>
            <script type="text/javascript" class="cookies_cat_2" nonce="8BA2D7" async>{`
            window.dataLayer = window.dataLayer || [];
            function gtag() {dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-NE6QCSGW4R');`}
            </script>
            <script type="text/javascript" class="cookies_cat_4" nonce="8BA2D7">{`
            (function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push({
              'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-MVQP872');`}
            </script>
            <script type="text/javascript" className="cookies_cat_4" nonce="8BA2D7" async>{`
        !function (f, b, e, v, n, t, s) {
if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
}(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '495260557545741');
        fbq('track', 'PageView');`}
            </script>
          </Helmet>
        </>
      }

      <header>
        <div className="container">
          <div className="row">
            <div className="col-4 col-md-3">
              <img src={require("../../img/new/logo@2x.png")} alt="BNP Paribas" className="img-fluid" id="logo" onClick={() => { setViewPage("") }} />
            </div>

            {viewPage == "" &&
              <div className="col-8 col-md-9 text-end d-none d-md-block">
                <a href="#">Zasady</a>
                <a href="#">FAQ</a>
                <a href="#" onClick={() => { setViewPage("Loteria") }}>Graj w LOTERII</a>
                <a href="#" onClick={() => { setViewPage("Evouchery") }}>Odbierz 50 zł</a>
                <a href="#" onClick={() => { setViewPage("BezcenneChwile") }}>Bezcenne® Chwile</a>
              </div>
            }

            {viewPage == "Loteria" &&
              <div className="col-8 col-md-9 text-end d-none d-md-block">
                <a href="#nagrody">Nagrody</a>
                <a href="#rules">Zasady</a>
                {/* <a href="#zwyciezcy">Zwycięzcy</a> */}
                <a href="#faq">FAQ</a>
                <a href="#" onClick={() => { setViewPage("Evouchery") }}>Odbierz 50 zł</a>
                <a href="#" onClick={() => { setViewPage("BezcenneChwile") }}>Bezcenne® Chwile</a>
              </div>
            }

            {viewPage == "Evouchery" &&
              <div className="col-8 col-md-9 text-end d-none d-md-block">
                <a href="#jak_wziac_udzial">Zasady</a>
                <a href="#">FAQ</a>
                <a href="#" onClick={() => { setViewPage("Loteria") }}>Graj w LOTERII</a>
                <a href="#" onClick={() => { setViewPage("BezcenneChwile") }}>Bezcenne® Chwile</a>
              </div>
            }

            {viewPage == "BezcenneChwile" &&
              <div className="col-8 col-md-9 text-end d-none d-md-block">
                <a href="#" onClick={() => { setViewPage("Loteria") }}>Graj w LOTERII</a>
                <a href="#" onClick={() => { setViewPage("Evouchery") }}>Odbierz 50 zł</a>
              </div>
            }

          </div>
        </div>
      </header>

      <div className="container">

        <>
          <section id="graj">
            <div className="row mb-5">
              <div className="col-12 col-lg-6">
                <img src={require("../../img/new/GO_mobile 2_small.jpg")} alt="" className="img-fluid" />
                {/* <img src={require("../img/new/mn_bnp_parasol_14.05.20220465.jpg")} alt="" className="img-fluid" /> */}
              </div>
              <div className="col-12 col-lg-6">
                <div className="green_bg p-5" style={{ height: "100%" }}>
                  <h3 className="nobold white_txt mb-4"><b>GRAJ Z BANKIEM</b> BNP PARIBAS</h3>
                  <div className={`box my-3 py-3 px-4 nm` + (viewPage == "Loteria" ? " current" : "")} onClick={() => { setViewPage("Loteria") }}>
                    <div className="text_c"><b>W loterii</b> pula nagród<br />o wartości <b>ponad 700 000 zł</b></div>
                    <div className="arrow_c"><i className="arrow right green"></i></div>
                  </div>
                  <div className={`box my-3 py-3 px-4 nm` + (viewPage == "Evouchery" ? " current" : "")} onClick={() => { setViewPage("Evouchery") }}>
                    <div className="text_c"><b>Odbierz 50 zł</b><br /> w e-voucherach do Żabki</div>
                    <div className="arrow_c"><i className="arrow right green"></i></div>
                  </div>
                  <div className={`box my-3 py-3 px-4 nm` + (viewPage == "BezcenneChwile" ? " current" : "")} onClick={() => { setViewPage("BezcenneChwile") }}>
                    <div className="text_c"><b>Odbierz</b> e-voucher o wartości <b>200&nbsp;&nbsp;&nbsp;zł</b><br className="d-none d-md-inline-block" /> w programie <b>Bezcenne® Chwile</b></div>
                    <div className="arrow_c"><i className="arrow right green"></i></div>
                  </div>
                  <div className="txt2 mt-4">Dowiedz się więcej o <span onClick={() => { setShowDownloadBanner(true); }} style={{ textDecoration: "underline", cursor: "pointer" }}>KONCIE W BANKU BNP PARIBAS</span></div>
                </div>
              </div>
            </div>
          </section>

          {viewPage == "" &&
            <section id="konto">
              <div className="row text-center mb-5">
                <div className="col-12">
                  <button type="button" className="button green" onClick={() => { setShowDownloadBanner(true) }}>Załóż konto <i className="arrow right"></i></button>
                </div>
              </div>
              <div className="row text-center mb-2">
                <div className="col-12">
                  <h3 className="nobold mb-3">KONTO W BANKU <b>BNP PARIBAS</b> TO:</h3>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12">
                  <img src={require("../../img/new/gomobile-mob@2x.jpg")} alt="" className="img-fluid d-md-none" />
                </div>
                <div className="col-12 col-md-12 col-lg-3 mb-3 mb-md-0">
                  <div className="adv_box pb-5 pb-md-0">
                    <div className="mb-4">
                      <img src={require("../../img/new/0zl@2x.png")} alt="" className="img-fluid free" />
                    </div>
                    <div className="adv_box_txt">
                      za otwarcie i <b>prowadzenie rachunku</b>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-3 mb-3 mb-md-0">
                  <div className="adv_box pb-5 pb-md-0">
                    <div className="mb-4">
                      <img src={require("../../img/new/0zl@2x.png")} alt="" className="img-fluid free" />
                    </div>
                    <div className="adv_box_txt">
                      miesięcznie za wydanie i&nbsp;&nbsp;użytkowanie <b>karty mobilnej i&nbsp;&nbsp;tradycyjnej</b> przez 12 miesięcy*
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-3 mb-3 mb-md-0">
                  <div className="adv_box pb-5 pb-md-5 pb-lg-0">
                    <div className="mb-4">
                      <img src={require("../../img/new/0zl@2x.png")} alt="" className="img-fluid free" />
                    </div>
                    <div className="adv_box_txt">
                      <b>za wypłaty gotówki w&nbsp;bankomatach</b> Banku BNP Paribas i&nbsp;&nbsp;sieci Planet Cash
                    </div>
                  </div>
                </div>
                <div className="col-12 d-md-none mb-5 txt4">* od dnia złożenia wniosku o kartę</div>
                <div className="col-12 col-md-12 col-lg-3 mb-3 mb-md-0 d-none d-md-block">
                  <div className="adv_box picture">
                    <img src={require("../../img/new/gomobile@2x.jpg")} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="row mb-5 addtxt">
                <div className="col-3"></div>
                <div className="col-3 d-none d-md-block">* od dnia złożenia wniosku o kartę</div>
                <div className="col-3"></div>
                <div className="col-3"></div>
              </div>
            </section>
          }
        </>

        <div id="scroll_pos"></div>

        <div className="row text-center mb-5">
          <div className="col-12">
            <button type="button" className="button green" onClick={() => { setShowDownloadBanner(true) }}>Załóż konto <i className="arrow right"></i></button>
          </div>
        </div>

        {viewPage == "Loteria" && <Loteria onShowDownloadBanner={setShowDownloadBanner} />}
        {viewPage == "Evouchery" && <Evouchery onShowDownloadBanner={setShowDownloadBanner} />}
        {viewPage == "BezcenneChwile" && <BezcenneChwile onShowDownloadBanner={setShowDownloadBanner} />}
      </div>

      <footer className="py-5 text-center">
        <a href="/jesteswygrywem/pdf/regulamin-promocji.pdf" target="_blank">Regulamin promocji</a>
        <a href="/jesteswygrywem/pdf/regulamin.pdf" target="_blank">Regulamin loterii</a>
        <a href="/jesteswygrywem/polityka-prywatnosci" target="_blank">Ochrona prywatności</a>
        <a href="/jesteswygrywem/pliki-cookies.pdf" target="_blank">Pliki cookies</a>
        <a href="/jesteswygrywem/pdf/warunki-korzystania.pdf" target="_blank">Warunki korzystania</a>
      </footer>

      {showDownloadBanner &&
        <>
          <div className="get_app_c d-none d-md-block" onClick={() => { setShowDownloadBanner(false) }}>
            <div className="get_app">
              <div className="row">
                <div className="col-5">
                  <img src={require("../../img/new/qr@2x.png")} alt="" className="img-fluid" />
                </div>
                <div className="col-7 txt5">
                  Zeskanuj QR kod, pobierz aplikację i&nbsp;&nbsp;&nbsp;załóż konto
                </div>
              </div>
            </div>
          </div>
          <div className="get_app_c d-md-none" onClick={() => { setShowDownloadBanner(false) }}>
            <div className="get_app mobile">
              <div className="row">
                <div className="col-12 txt5 mb-2">
                  Pobierz aplikację i&nbsp;&nbsp;&nbsp;załóż konto
                </div>
              </div>
              <div className="row">
                <div className="col-8 offset-2 mb-2">
                  <a href="https://play.google.com/store/apps/details?id=com.finanteq.finance.bgz" target="_blank"><img src={require("../../img/new/Pobierz-z-Google-Play@2x.png")} alt="" className="img-fluid" /></a>
                </div>
              </div>
              <div className="row">
                <div className="col-8 offset-2">
                  <a href="https://apps.apple.com/pl/app/bnp-paribas-gomobile/id1267771198?l=pl" target="_blank"><img src={require("../../img/new/Pobierz-z-App-Store@2x.png")} alt="" className="img-fluid" /></a>
                </div>
              </div>
            </div>
          </div>
        </>
      }

      {showScroll &&
        <div id="scroll_to_top" onClick={onGoToTop}><i className="arrow up" /></div>
      }

    </>

  );

}