import React from "react";
import { useEffect, useState } from 'react';
import FAQ from "./FAQ";

export default function Evouchery({ onShowDownloadBanner }) {

	return (
		<>
			<section id="jak_wziac_udzial">
				<div className="row text-center mb-2 mt-5">
					<div className="col">
						<h2>JAK WZIĄĆ UDZIAŁ W PROMOCJI?</h2>
					</div>
				</div>

				<div className="row text-center">
					<div className="col">
						<div className="line"></div>
					</div>
				</div>

				<div className="row text-center mt-5">
					<div className="col-6 col-md-3">
						<div className="row">
							<div className="col-12">
								<img src={require("../img/new/voucher_step1@2x.jpg")} alt="" className="img-fluid" />
							</div>
							<div className="col-12 col-md-10 offset-md-1 info mt-4">
								Pobierz <b>aplikację GOmobile</b> Banku BNP Paribas
							</div>
						</div>
					</div>
					<div className="col-6 col-md-3">
						<div className="row pt-md-4">
							<div className="col-12 pt-md-5">
								<img src={require("../img/new/voucher_step2@2x.jpg")} alt="" className="img-fluid" />
							</div>
							<div className="col-12 col-md-10 offset-md-1 info mt-4">
								Otwórz konto osobiste w&nbsp;&nbsp;<b>promocji „Jesteś wygrywem”*</b>
								<div className="small_info">* Możesz to zrobić tylko online.</div>
							</div>
						</div>
					</div>
					<div className="col-6 col-md-3">
						<div className="row">
							<div className="col-12">
								<img src={require("../img/new/voucher_step3@2x.jpg")} alt="" className="img-fluid" />
							</div>
							<div className="col-12 col-md-10 offset-md-1 info mt-4">
								Zadbaj o wpływy na konto <b>minimum 1000 zł</b>
							</div>
						</div>
					</div>
					<div className="col-6 col-md-3">
						<div className="row pt-md-4">
							<div className="col-12 pt-md-5">
								<img src={require("../img/new/voucher_step4@2x.jpg")} alt="" className="img-fluid" />
							</div>
							<div className="col-12 col-md-10 offset-md-1 info mt-4">
								<b>Odbierz w promocji 50 zł</b> w&nbsp;&nbsp;e-voucherach do Żabki
							</div>
						</div>
					</div>
				</div>
				<div className="row text-center my-5">
					<div className="col-12">
						<button type="button" className="button green" onClick={() => { onShowDownloadBanner(true) }}>Pobierz aplikację <img src={require("../img/new/arrow_right.png")} style={{ position: "relative", top: "-7px", marginLeft: "12px" }} /></button>
					</div>
				</div>
			</section>

			<div className="row text-center">
				<div className="col">
					<div className="line"></div>
				</div>
			</div>

			<section id="rules2">
				<div className="row">
					<div className="col-12 text-center mt-4 mb-4">
						<h3>ZASADY</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-7">
						<div className="green_bg p-5" style={{ height: "100%" }}>
							<div className="row mb-3">
								<div className="col-2 col-lg-1">
									<img src={require("../img/new/b1@2x.png")} alt="" className="num" />
								</div>
								<div className="col-10 col-lg-11">
									<b>Otwórz konto</b> w promocji <b>„Jesteś wygrywem”</b> i aktywuj aplikację <b>GOmobile</b> w okresie promocji (chyba że zrobiłeś to już wcześniej).
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-2 col-lg-1">
									<img src={require("../img/new/b2@2x.png")} alt="" className="num" />
								</div>
								<div className="col-10 col-lg-11">
									<b>Zadbaj o wpływy na konto</b> w łącznej wysokości <b>minimum 1000 zł.</b> Ta&nbsp;&nbsp;&nbsp;suma musi się pojawić na koncie w miesiącu, w którym nastąpiło otwarcie konta lub następnym miesiącu po otwarcia konta.
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-2 col-lg-1">
									<img src={require("../img/new/b3@2x.png")} alt="" className="num" />
								</div>
								<div className="col-10 col-lg-11">
									W dniu wydania nagrody musisz być <b>posiadaczem konta</b> osobistego wraz z kartą oraz dostępem do bankowości elektronicznej.
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-12 col-md-11 offset-md-1">
									<button type="button" className="button">Załóż konto <i className="arrow right green"></i></button>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-12 col-md-11 offset-md-1 txt8">
									Promocja skierowana jest do nowych klientów, czyli osób które w okresie 12 miesięcy przed złożeniem wniosku o&nbsp;&nbsp;&nbsp;otwarcie konta osobistego w promocji nie były posiadaczami ani współposiadaczami rachunku oszczędnościowo-rozliczeniowego w&nbsp;Banku BNP Paribas.
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-5 big_img">
						<img src={require("../img/new/mn_bnp_parasol_13.05.2022_0908.jpg")} alt="" className="img-fluid" />
					</div>
				</div>

				{/* <div className="row text-left remarks mb-2 mt-4">
					<div className="col-12">
						Promocja skierowana jest do nowych klientów, czyli osób które w okresie 12 miesięcy przed złożeniem wniosku o otwarcie konta osobistego w promocji nie były posiadaczami ani współposiadaczami rachunku oszczędnościowo-rozliczeniowego w&nbsp;Banku BNP Paribas.
					</div>
				</div> */}

				<div className="row text-center reg mb-5">
					<div className="col-12">
						<a href="/jesteswygrywem/pdf/regulamin-promocji.pdf">Regulamin promocji</a>
					</div>
				</div>
			</section>

			<FAQ />

		</>
	);
}
