import React, { useState } from 'react';
import moment from "moment";
import 'moment/locale/pl'
import axios from 'axios';

export default class Calendar extends React.Component {
	weekdayshort = moment.weekdaysShort();
	minDate = new Date("2023-02-01");
	maxDate = new Date("2024-04-01");

	state = {
		jsDateObject: new Date(),
		dateObject: moment(),
		allmonths: moment.months(),

		//selectedYear: new Date().getFullYear(), //initial
		//selectedMonth: new Date().getMonth(), //initial

		selectedDay: null,
		selectedDate: new Date(),
		showPrev: true,
		showNext: true
	};

	daysInMonth = () => {
		return new Date(this.state.jsDateObject.getFullYear(), (this.state.jsDateObject.getMonth() + 1), 0).getDate();
	};
	//year = () => {
	//	return this.state.dateObject.format("Y"); //deprecated
	//};
	currentDay = () => {
		return this.state.dateObject.format("D"); //deprecated
	};
	firstDayOfMonth = () => {
		// akrobacje żeby wyciągnąć numer pierwszego dnia miesiąca
		let firstDay = new Date(this.state.jsDateObject.getFullYear() + "-" + (this.state.jsDateObject.getMonth() + 1)).getDay();

		firstDay = parseInt(firstDay) - 1;

		if (firstDay === -1)
			firstDay = 6;

		return firstDay;
	};

	month = () => {
		//console.log("month now", this.state.jsDateObject.getMonth());
		return this.state.allmonths[this.state.jsDateObject.getMonth()];
	};

	year = () => {
		//console.log("year now", this.state.jsDateObject.getFullYear());
		return this.state.jsDateObject.getFullYear();
	};

	onPrev = () => {
		console.log("ss");
		if (new Date(this.state.jsDateObject.getFullYear() + "-" + ((this.state.jsDateObject.getMonth() + 1).toString().padStart(2, '0')) + "-" + "01") > this.minDate) {
			var curr = this.state.jsDateObject;
			curr.setMonth(curr.getMonth() - 1, 1);

			this.setState({
				jsDateObject: curr,
				showNext: true
			});
		}
		else {
			this.setState({
				showPrev: false
			});
		}
	};
	onNext = () => {
		if (this.state.jsDateObject < this.maxDate) {
			var curr = this.state.jsDateObject;
			curr.setMonth(curr.getMonth() + 1, 1);

			this.setState({
				jsDateObject: curr,
				showPrev: true
			});
		}
		else {
			this.setState({
				showNext: false
			});
		}
	};
	setYear = year => {
		let dateObject = Object.assign({}, this.state.dateObject);
		dateObject = moment(dateObject).set("year", year);
		this.setState({
			dateObject: dateObject,
			showYearNav: !this.state.showYearNav
		});
	};
	onYearChange = e => {
		this.setYear(e.target.value);
	};
	getDates(startDate, stopDate) {
		var dateArray = [];
		var currentDate = moment(startDate);
		var stopDateMoment = moment(stopDate);

		while (currentDate <= stopDateMoment) {
			dateArray.push(moment(currentDate).format("YYYY"));
			currentDate = moment(currentDate).add(1, "year");
		}

		return dateArray;
	};
	onDayClick = (e, d) => {
		this.setState({ selectedDay: d },
			() => {
				let selectedDate = this.state.jsDateObject.getFullYear() + "-" + (this.state.jsDateObject.getMonth() + 1).toString().padStart(2, '0') + "-" + this.state.selectedDay.toString().padStart(2, '0');
				this.getData(selectedDate);
			}
		);
	};

	getData = async (date) => {

		const data = {
			date: date
		};

		try {
			//const response = await axios.post("https://lp.bnpparibas.pl/jesteswygrywem/ajax/get_winner_list", data);
			const response = await axios.post("https://api-301bnp-zabka.iq-digital.pl/get_winner_list.php", data);

			//if (response.data.status_id === 0) {
			//console.log(response.data.first);

			this.props.onResult(response.data);

			//}
			//else
			//	console.log("failed");
			//else
			//	setMessage(response.data.msg);

		} catch (error) {
			console.log(error);
		}
	}

	render() {
		let blanks = [];
		let daysInMonth = [];

		for (let i = 0; i < this.firstDayOfMonth(); i++) {
			blanks.push(<td key={this.month + "-" + i} className="calendar-day empty">{""}</td>);
		}

		for (let d = 1; d <= this.daysInMonth(); d++) {
			let currentDay = d === this.currentDay() ? "today" : "";
			let selectedClass = (d === this.state.selectedDay ? " active" : "")

			daysInMonth.push(
				<td key={d} className={`calendar-day ${currentDay} ${selectedClass}`} onClick={e => {
					this.onDayClick(e, d);
				}}>{d}</td>
			);
		}

		var totalSlots = [...blanks, ...daysInMonth];
		let rows = [];
		let cells = [];

		totalSlots.forEach((row, i) => {
			if (i % 7 !== 0) {
				cells.push(row);
			} else {
				rows.push(cells);
				cells = [];
				cells.push(row);
			}
			if (i === totalSlots.length - 1) {
				// let insertRow = cells.slice();
				rows.push(cells);
			}
		});

		let daysinmonth = rows.map((d, i) => {
			return <tr key={this.month + "d" + i}>{d}</tr>;
		});

		return (
			<table className="calendar">
				<thead>
					<tr>
						<th colSpan="7" className="month">
							{this.state.showPrev &&
								<span
									onClick={e => {
										this.onPrev();
									}}
									className="calendar-button button-prev arrow float-start nm"
								>
									<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAlCAYAAACpmHIGAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUSEutl41RwzAMheMJYAPYADYgjMAEtBPQTkKZgDIB3YCOABt0hDJBeC8nc04q/8ix7nxxm+R7kmpLrhuG4b7ruleMHmOPsXXOnXFdbA7wEyg3Aekb88cWAoQPiosUeIIAhauNcKbiWSEwNYyAQlVG+DXePGLctRZwBIrAAdOHlgIj3FsiRXxkjRQxhcU2gUsUsd/ALHABbymgwkVghet7JAc7pGiby08UXiCwh8A6JZCELxXIwkWA9eeIcaV4ygWg1qMieIGAWo+K4TUCJrgI3OLK3ayVi0kEZrgIFNWjKnixQG4jpO6jFm1wn11Ms7clnqd28Ac22KoKnvXYOUbUmeGWsmyCW8AmzxPgX4A2WiPJei4t8AsA1pe5EdzHmniu5HKzVIGTaVnisQ8v1uaYgk8M1pG5/Ugqskc+rUETzFQwJdXgi7TIobQJeAIHmNuZdULzeNzOSiTJr/yJiy/GOn0VePRcPG4O9vATJuH53IfKpruzpiJ8np5zSc27uvlcqDlBOL17CW42Af+vFgj0+MD1fVj6byKM4A8z7crg/xUrsAAAAABJRU5ErkJggg==" alt="" className="next" style={{ transform: "rotate(180deg)" }} />
								</span>
							}
							<span className="calendar-label month_name" style={{ fontSize: "0.8em", }}>
								{this.month()} {this.year()}
								{/* <br /><span>{this.state.jsDateObject.toLocaleDateString()}</span> */}
							</span>

							{this.state.showNext &&
								<span
									onClick={e => {
										this.onNext();
									}}
									className="calendar-button button-next arrow float-end nm"
								>
									<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAlCAYAAACpmHIGAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUSEutl41RwzAMheMJYAPYADYgjMAEtBPQTkKZgDIB3YCOABt0hDJBeC8nc04q/8ix7nxxm+R7kmpLrhuG4b7ruleMHmOPsXXOnXFdbA7wEyg3Aekb88cWAoQPiosUeIIAhauNcKbiWSEwNYyAQlVG+DXePGLctRZwBIrAAdOHlgIj3FsiRXxkjRQxhcU2gUsUsd/ALHABbymgwkVghet7JAc7pGiby08UXiCwh8A6JZCELxXIwkWA9eeIcaV4ygWg1qMieIGAWo+K4TUCJrgI3OLK3ayVi0kEZrgIFNWjKnixQG4jpO6jFm1wn11Ms7clnqd28Ac22KoKnvXYOUbUmeGWsmyCW8AmzxPgX4A2WiPJei4t8AsA1pe5EdzHmniu5HKzVIGTaVnisQ8v1uaYgk8M1pG5/Ugqskc+rUETzFQwJdXgi7TIobQJeAIHmNuZdULzeNzOSiTJr/yJiy/GOn0VePRcPG4O9vATJuH53IfKpruzpiJ8np5zSc27uvlcqDlBOL17CW42Af+vFgj0+MD1fVj6byKM4A8z7crg/xUrsAAAAABJRU5ErkJggg==" alt="" className="next" />
								</span>
							}
						</th>
					</tr>
				</thead>
				{
					<tbody>{daysinmonth}</tbody>
				}
			</table>
		);
	}
}
