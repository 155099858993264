import React from "react";
import { useEffect, useState } from 'react';
import FAQ from "./FAQ";
import NewCalendar from "./NewCalendar";
import Calendar from "./Calendar";

export default function Loteria({ onShowDownloadBanner }) {
	const [dailyPrize, setDailyPrize] = useState([]);
	const [weeklyPrize, setWeeklyPrize] = useState([]);
	const [monthlyPrize, setMonthlyPrize] = useState([]);
	const [mainPrize, setMainPrize] = useState([]);

	const onCalendarClick = (data) => {
		setDailyPrize(data[0].daily);
		setWeeklyPrize(data[0].weekly);
		setMonthlyPrize(data[0].monthly);
		setMainPrize(data[0].main);
	}

	return (
		<>
			<div className="row text-center mt-4">
				<div className="col">
					<h1 className="mb-3">JESTEŚ WYGRYWEM</h1>
				</div>
			</div>
			<div className="row text-center">
				<div className="col">
					<div className="line">
					</div>
				</div>
			</div>
			<div className="row text-center my-3">
				<div className="col my-3 subtxt">
					<b>Weź udział w&nbsp;&nbsp;&nbsp;loterii</b> i graj o&nbsp;&nbsp;&nbsp;nagrody. W&nbsp;&nbsp;puli <b>ponad 700&nbsp;&nbsp;&nbsp;000 zł.*</b>
				</div>
			</div>

			<section id="nagrody">
				<div className="row">
					<div className="col-12 col-md-5">
						<div className="green_bg p-5 py-4" style={{ height: "100%" }}>
							<h3 className="white_txt">Nagrody</h3>
							<ul>
								<li>Codziennie <b>500 zł</b>
									<div>w e-voucherach do Żabki</div>
								</li>
								<li>Co tydzień <b>5 000 zł</b></li>
								<li>Co miesiąc <b>50 000 zł</b></li>
								<li>W finale <b>500 000 zł</b></li>
							</ul>
							<div>
								<button type="button" className="button" onClick={() => { onShowDownloadBanner(true) }}>Załóż konto <i className="arrow right green"></i></button>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-7 big_img">
						<img src={require("../img/new/mn_bnp_parasol_14.05.20220465.jpg")} alt="" className="img-fluid" />
					</div>
				</div>
			</section>

			<div className="row text-left remarks mb-4 mt-3"><div className="col-12">* Pula na dzień 01.10.2023 r. Całkowita pula nagród przez cały czas trwania loterii to ponad 1 500 000 zł.</div></div>

			<section id="jak_wziac_udzial">
				<div className="row text-center mb-2 mt-5">
					<div className="col">
						<h2>JAK WZIĄĆ UDZIAŁ W&nbsp;&nbsp;&nbsp;LOTERII?</h2>
					</div>
				</div>
				<div className="row text-center">
					<div className="col">
						<div className="line">
						</div>
					</div>
				</div>
				<div className="row text-center mt-5">
					<div className="col-6 col-md-3">
						<div className="row">
							<div className="col-12">
								<img src={require("../img/new/step1@2x.jpg")} alt="" className="img-fluid" />
							</div>
							<div className="col-12 col-md-8 offset-md-2 info mt-4">
								Zeskanuj QR kod i&nbsp;&nbsp;pobierz <b>aplikację GOmobile</b> Banku BNP Paribas
							</div>
						</div>
					</div>
					<div className="col-6 col-md-3">
						<div className="row pt-md-4">
							<div className="col-12 pt-md-5">
								<img src={require("../img/new/step2@2x.jpg")} alt="" className="img-fluid" />
							</div>
							<div className="col-12 col-md-8 offset-md-2 info mt-4">
								Otwórz konto osobiste w&nbsp;&nbsp;<b>promocji „Jesteś wygrywem”*</b>
								<div className="small_info">* Możesz to zrobić tylko online.</div>
							</div>
						</div>
					</div>
					<div className="col-6 col-md-3">
						<div className="row">
							<div className="col-12">
								<img src={require("../img/new/step3@2x.jpg")} alt="" className="img-fluid" />
							</div>
							<div className="col-12 col-md-8 offset-md-2 info mt-4">
								Zgłoś swój <b>udział w&nbsp;&nbsp;loterii „Wygrywaj z&nbsp;&nbsp;&nbsp;kontem w Banku BNP Paribas”</b>
							</div>
						</div>
					</div>
					<div className="col-6 col-md-3">
						<div className="row pt-md-4">
							<div className="col-12 pt-md-5">
								<img src={require("../img/new/step4@2x.jpg")} alt="" className="img-fluid" />
							</div>
							<div className="col-12 col-md-8 offset-md-2 info mt-4">
								Płać <b>kartą mobilną*</b> i&nbsp;&nbsp;graj o&nbsp;&nbsp;<b>nagrody</b>
								<div className="small_info">* Co najmniej 10 zł.</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row text-center my-5">
					<div className="col-12">
						<button type="button" className="button green" onClick={() => { onShowDownloadBanner(true) }} > Pobierz aplikację <img src={require("../img/new/arrow_right.png")} style={{ position: "relative", top: "-7px", marginLeft: "12px" }} /></button>
					</div>
				</div>
			</section>

			<div className="row text-center mb-5">
				<div className="col">
					<div className="line">
					</div>
				</div>
			</div>

			<section id="rules">
				<div className="row">
					<div className="col-12 col-md-5 big_img">
						<img src={require("../img/new/_DSF8061.jpg")} alt="" className="img-fluid" />
					</div>
					<div className="col-12 col-md-7">
						<div className="green_bg p-5" style={{ height: "100%" }}>
							<h3>ZASADY UDZIAŁU W&nbsp;&nbsp;LOTERII</h3>
							<div className="row mb-3">
								<div className="col-2 col-lg-1">
									<img src={require("../img/new/b1@2x.png")} alt="" className="num" />
								</div>
								<div className="col-10 col-lg-11">
									Pobierz aplikację <b>GOmobile</b> Banku BNP Paribas.
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-2 col-lg-1">
									<img src={require("../img/new/b2@2x.png")} alt="" className="num" />
								</div>
								<div className="col-10 col-lg-11">
									Otwórz w aplikacji <b>konto osobiste z promocją „Jesteś Wygrywem”</b> (Konto Otwarte na Ciebie) z mobilną kartą.
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-2 col-lg-1">
									<img src={require("../img/new/b3@2x.png")} alt="" className="num" />
								</div>
								<div className="col-10 col-lg-11">
									<b>Zgłoś udział w loterii</b>, klikając w baner widoczny po zalogowaniu do aplikacji w górnej części ekranu.
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-2 col-lg-1">
									<img src={require("../img/new/b4@2x.png")} alt="" className="num" />
								</div>
								<div className="col-10 col-lg-11">
									Dokonaj <b>pierwszej transakcji w Żabce</b>, płacąc mobilną kartą minimum 10 zł. Każde kolejne wydane <b>10 zł to kolejna szansa na wygraną.</b>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-12 col-md-11 offset-md-1">
									<button type="button" className="button" onClick={() => { onShowDownloadBanner(true) }}>Załóż konto <i className="arrow right green"></i></button>
								</div>
							</div>

							<div className="row mt-4">
								<div className="col-12 col-md-11 offset-md-1 txt8">
									Loteria kierowana jest do osób, które w okresie 12 miesięcy przed złożeniem wniosku o zawarcie umowy rachunku osobistego w ramach promocji „Jesteś wygrywem” nie były posiadaczem albo współposiadaczem rachunku oszczędnościowo-rozliczeniowego w Banku BNP Paribas.
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-12 col-md-10 offset-md-1 black_txt py-5 text-center">
						<b>WAŻNE!</b> Transakcji można dokonywać w dowolnych punktach handlowych na terenie Polski, stacjonarnie lub przez Internet, jednak za te dokonane <b>w Żabce otrzymujesz 10 x więcej losów.</b>
					</div>
				</div>
				<div className="row text-center">
					<div className="col-12 col-md-5 offset-md-1 mb-5 mb-md-0">
						<img src={require("../img/new/box1.png")} alt="" className="img-fluid" />
					</div>
					<div className="col-12 col-md-5">
						<img src={require("../img/new/box2.png")} alt="" className="img-fluid" />
					</div>
				</div>
				<div className="row text-center reg my-4">
					<div className="col-12">
						<a href="/jesteswygrywem/pdf/regulamin.pdf" target="_blank">Regulamin loterii</a>
					</div>
				</div>
				<div className="row text-left remarks mb-4 mt-2">
					<div className="col-12">
						W loterii rejestrowane są transakcje dokonywane od 13 lutego 2023 do 11 lutego 2024. Loteria kończy się 12 czerwca 2024 roku.
					</div>
				</div>
			</section>

			<div className="row text-center mb-5">
				<div className="col">
					<div className="line">
					</div>
				</div>
			</div>
			{/* 
			<section id="zwyciezcy" className="section winners">
				<div className="wrapper">
					<div className="content">
						<div className="row cl ">
							<div className="col-12 text-center">
								<h2 className="title green">ZWYCIĘZCY</h2>
								<h3>Wybierz dzień i sprawdź listę zwycięzców.</h3>
							</div>
						</div>
						<div className="row cl mb-4">
							<div className="col-12 text-center">
								<Calendar onResult={onCalendarClick} />
							</div>
						</div>
						<div className="row winner_container mb-5">
							<div className="col-12 col-md-4">
								<div className="winner_apla text-center">
									<p className="prize_info">Nagroda codzienna</p>
									<hr />
									<div className="winner_list dynamic" data-prize_id="4">
										<div dangerouslySetInnerHTML={{ __html: dailyPrize }} />
									</div>
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="winner_apla text-center">
									<p className="prize_info">Nagroda tygodniowa</p>
									<hr />
									<div className="winner_list dynamic" data-prize_id="3">
										<div dangerouslySetInnerHTML={{ __html: weeklyPrize }} />
									</div>
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="winner_apla text-center">
									<p className="prize_info">Nagroda miesięczna</p>
									<hr />
									<div className="winner_list dynamic" data-prize_id="2">
										<div dangerouslySetInnerHTML={{ __html: monthlyPrize }} />
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>
			</section>
		 */}

			<section id="zwyciezcy">
				<div className="row text-center">
					<div className="col">
						<h2>Zwycięzcy</h2>
						Wybierz miesiąc i sprawdź listę zwycięzców.

						<div className="my-5 mt-4">
							<NewCalendar onResult={onCalendarClick} />
						</div>
					</div>
				</div>
				<div className="row text-center">
					<div className="col-12 col-lg-6 col-xl-4 mb-5">
						<div className="winners_box px-2">
							<div className="title">NAGRODA CODZIENNA <b>500 zł</b></div>
							<div className="scroller">
								<div className="list text-start">
									{dailyPrize?.map((i, e) => {
										return (<div className="list_item row" key={e}>
											<div className="col-1 offset-md-1 num">{e + 1}</div>
											<div className="col-6 col-md-5">{i.name}</div>
											<div className="col-5">{i.code}</div>
										</div>)
									})}
									{/* <div className="list_item row">
										<div className="col-1 num">1</div>
										<div className="col-6">Jan Andrzej W.</div>
										<div className="col-5">3X7OPE44E8</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-6 col-xl-4 mb-5">
						<div className="winners_box">
							<div className="title">NAGRODA TYGODNIOWA <b>5 000 zł</b></div>
							<div className="scroller">
								<div className="list text-start">
									{weeklyPrize?.map((i, e) => {
										return (<div className="list_item row" key={e}>
											<div className="col-1 offset-md-1 num">{e + 1}</div>
											<div className="col-6 col-md-5">{i.name}</div>
											<div className="col-5">{i.code}</div>
										</div>)
									})}
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-xl-4 mb-5">
						<div className="winners_box">
							<div className="title">NAGRODA MIESIĘCZNA <b>50 000 zł</b></div>
							{monthlyPrize?.map((i, e) => {
								return (<div key={e}>
									<div>{i.name}</div>
									<span style={{ fontSize: "0.9em" }}>{i.code}</span>
								</div>)
							})}
							{/* <div>
								JAN ANDRZEJ W.
								<br />3X7OPE44E8
							</div> */}
						</div>
					</div>
				</div>

				<div className="row text-center mb-5">
					<div className="col-12 mt-3 mb-3">
						<div className="winners_box main">
							<div className="title">NAGRODA GŁÓWNA <b>500 000 zł</b></div>
							{mainPrize?.length > 0 ?
								mainPrize?.map((i, e) => {
									return (<div key={e}>
										<div>{i.name}</div>
										<span style={{ fontSize: "0.9em" }}>{i.code}</span>
									</div>)
								})
								:
								<div>
									Laureat zostanie opublikowany po weryfikacji.
								</div>
							}

						</div>
					</div>
				</div>
			</section>

			<FAQ />

			<section id="disclaimer" className="my-5">
				Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 Kodeksu cywilnego.
				<br /><br />
				Rejestracja w Loterii „Wygrywaj z kontem w Banku BNP Paribas” i możliwość wykonywania transakcji premiowanych losami trwa od 13.02.2023 r. do 11.02.2024 r. Organizatorem Loterii „Wygrywaj z kontem w Banku BNP Paribas” jest IQ Marketing (Poland) sp. z o.o. Szczegółowe warunki Loterii znajdziesz w regulaminie Loterii dostępnym na lp.bnpparibas.pl/jesteswygrywem lub w siedzibie organizatora.
				<br /><br />
				Konto Otwarte na Ciebie (rachunek płatniczy) z kartą debetową Karta Otwarta na Dzisiaj („Konto”), o którym mowa w regulaminie promocji „Jesteś wygrywem” jest oferowane przez BNP Paribas Bank Polska S.A. Informacje o Koncie , w tym o opłatach i prowizjach, dostępne są na stronie www.bnpparibas.pl oraz pod numerem 500 990 500 lub +48 22 134 00 00 (infolinia czynna 7 dni w tygodniu, 24 godziny na dobę, opłata za połączenie według cennika operatora).
			</section>
		</>
	);
}
