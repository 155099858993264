import React, { useState } from 'react';

const Collapsible = (props) => {
	const [open, setOpen] = useState(false);

	const toggle = () => {
		console.log("togg");
		setOpen(!open);
	};

	return (
		<div className="foldable my-2">
			<div className="row">
				<div className="col-2 col-md-1">
					<img src={require("../img/new/question_switch.png")} alt="" className={`img-fluid opening_icon` + (open ? ` open` : ``)} onClick={toggle} />
				</div>

				<div className="col-10 col-md-11">
					<div className="question" onClick={toggle}>{props.label}</div>
					{open && (
						<div className="answer">
							{props.children}
						</div>
					)}
				</div>
			</div>
		</div>

		// <div>
		// 	<button onClick={toggle}>{props.label}</button>
		// 	{open && (
		// 		<div className="toggle">
		// 			{props.children}
		// 		</div>
		// 	)}
		// </div>
	)
}
export default Collapsible;